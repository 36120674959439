.breadcrumb {
  background-color: transparent;
  padding-left: 0;
  .breadcrumb-item {
    &.active {
      color: var(--primary);
    }
    a {
      color: #000;
    }
  }
}
