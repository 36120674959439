// declare or overide bs theme color here
// :root {
//    --primary: #02AFA0;
//    --primary-darker: #019488;
// }

// try change this var through javascript if can
// $theme-colors:(
//     "primary" : #02AFA0,
// )

:root {
  --dark: #2f243a !important;
}

@import './token';

// * setup first time animation delay
$animation-delay: 2.1s;

$position-fixer: 5%;
$primary-color: var(--primary);
$primary-contrast: var(--primary-contrast);
$primary-dark: var(--primary-darker);
$luxi-gold: #c1a302;
$giftano-orange: #fc9e4f;
$giftano-red: #af2a20;
$giftano-purple: #5700a6;
$dark: #2f243a;
$lightgreen: #daf3f1;
$white: #fff;

// color state
$giftano-primary-hover: $primary-dark;
$giftano-primary-focus: $primary-dark;

//  * detail variables
$box-radius: 8px;
$line-height: 1.4rem * 3;

.btn {
  &-dark {
    background-color: $dark;
  }
  &-white {
    background-color: $white;
    color: $dark;
  }
}

.text {
  &-giftano {
    color: $primary-color;
  }
}

//  ? Mixins
@mixin MyNavbar($height) {
  .container {
    // max-width: 580px;
    // max-width: 720px;
    position: relative;
  }
  .rectangle-header {
    // background-color: var(--primary);
    // height: $height;
    @media only screen and (max-width: 767px) {
      height: $height + 60px;
    }
  }
  .merchant-images {
    border-radius: 8px !important;
  }
  .logo {
    margin-top: 12px;
    margin-bottom: 12px;
    max-height: 40px;
    font-size: 18px;
    line-height: 28px;
  }
  .navbar {
    // padding-left: 0;
    padding-right: 0;
    z-index: 20;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    &-btn {
      &__text {
        display: block;
      }
    }
    .gift-counter {
      background-color: #cc3125;
      color: white;
      padding: 1px 3px;
      border-radius: 7px;
      position: absolute;
      width: 12px;
      height: 12px;
      left: 19px;
      font-size: 12px;
      top: 3px;
    }
    .nav-link[alt='My Gifts'] {
      position: relative;
    }
    img {
      max-height: 40px;
      width: auto;
    }
    &-back {
      position: absolute;
      left: 0;
      z-index: 10;
    }
  }
}

@mixin clamp-line {
  &:focus {
    outline: none;
  }
  &:before {
    content: '... ';
    color: rgba(51, 51, 51, 0.45);
  }
  &[aria-expanded='true'] {
    position: relative;
    padding: 0;
    bottom: 0.5em;
    &:before {
      content: '';
    }
  }
  position: absolute;
  right: 0;
  bottom: -2px;
  border: none;
  background-color: white;
  color: var(--primary);
}

@mixin full-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

@mixin swipezor {
  border: none;
  background-color: #f2f2f2;
  border-radius: 24px;
  font-size: 18px;
  line-height: 28px;
  color: var(--gf-violet-400);
  font-weight: 700;
  .swipezor {
    &-overlay {
      background: transparent;
      // width: 80px !important;
      min-width: 80px;
      &-wrapper {
        background-color: #f2f2f2;
        border-radius: 20px;
        color: #4f4f4f;
      }
      &-txt {
        color: #4f4f4f !important;
      }
    }
    &-caret-wrapper {
      &::before {
        content: '';
        background: #f2f2f2;
        width: 20px;
        height: 100%;
        left: -20px;
        position: absolute;
      }
      background-color: $primary-color !important;
      max-width: 80px !important;
      width: 75px !important;
      border-radius: 20px;
      height: 86% !important;
      transform: translateY(-50%) translateX(0) !important;
    }
  }
}
