/* extra extra small devices */
@media only screen and (max-width: 374px) {
  .section--cards {
    padding-top: 34%;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
  }

  .front-sender {
    font-size: 18px;
    top: 78%;
    right: 20%;
  }

  .front-recipient {
    font-size: 18px;
    top: 33%;
    left: 22%;
  }

  .front-content {
    font-size: 14px;
    top: 40%;
    left: 22.5%;
    max-height: 4em;
  }

  .env-card.lift-card {
    -webkit-animation: lift-card-mobile 1.8s ease-in-out 0.8s forwards;
    animation: lift-card-mobile 1.8s ease-in-out 0.8s forwards;
    animation-delay: 3s + $animation-delay;
    &.skip-animation {
      animation: lift-card-mobile 1.8s ease-in-out 0.8s forwards;
      animation-delay: 0s;
      animation-duration: 0s;
    }
  }

  .env-outer.drop-env {
    -webkit-animation: drop-env 1.8s ease-in-out 0.8s forwards;
    animation: drop-env 1.8s ease-in-out 0.8s forwards;
    animation-delay: 4.8s + $animation-delay;
    &.skip-animation {
      animation: drop-env 1.8s ease-in-out 0.8s forwards;
      animation-delay: 0s;
      animation-duration: 0s;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 374px) {
  .section--cards {
    // padding-top: 17%;
    width: 90%;
    padding-top: 34%;
    padding-left: 1em;
    padding-right: 1em;
  }

  .front-sender {
    font-size: 25px;
    top: 78%;
    right: 20%;
  }

  .front-recipient {
    font-size: 25px;
    top: 33%;
    left: 23%;
  }

  .front-content {
    font-size: 14px;
    top: 40%;
    left: 22.5%;
  }

  .env-card.lift-card {
    -webkit-animation: lift-card-mobile 1.8s ease-in-out 0.8s forwards;
    animation: lift-card-mobile 1.8s ease-in-out 0.8s forwards;
    animation-delay: 3s + $animation-delay;
    &.skip-animation {
      animation: lift-card-mobile 1.8s ease-in-out 0.8s forwards;
      animation-delay: 0s;
      animation-duration: 0s;
    }
  }

  .env-outer.drop-env {
    -webkit-animation: drop-env 1.8s ease-in-out 0.8s forwards;
    animation: drop-env 1.8s ease-in-out 0.8s forwards;
    animation-delay: 4.8s + $animation-delay;
    &.skip-animation {
      animation: drop-env 1.8s ease-in-out 0.8s forwards;
      animation-delay: 0s;
      animation-duration: 0s;
    }
  }
  .btn-envelope {
    font-size: 0.6rem;
  }

  .footer {
    img {
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
  }
  .modal-showcode {
    .modal-dialog {
      max-width: 70% !important;
    }
    .modal-header {
      // border-top: 1em solid var(--primary) !important;
      // .close {
      //   margin-top: 0 !important;
      // }
    }

    .redeem-status {
      top: 0 !important;
      right: auto !important;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .section--cards {
    padding-top: 16%;
    width: 80%;
  }

  .front-sender {
    font-size: 25px;
    top: 75%;
    right: 20%;
  }

  .front-recipient {
    font-size: 25px;
    top: 36%;
    left: 20%;
  }

  .front-content {
    font-size: 16px;
    line-height: 1.4;
    word-spacing: 5px;
    top: 40%;
    left: 20%;
    margin-left: auto;
    margin-right: auto;
  }

  .env-card.lift-card {
    -webkit-animation: lift-card 1.8s ease-in-out 0.8s forwards;
    animation: lift-card 1.8s ease-in-out 0.8s forwards;
    animation-delay: 3s + $animation-delay;
    &.skip-animation {
      animation: lift-card-mobile 1.8s ease-in-out 0.8s forwards;
      animation-delay: 0s;
      animation-duration: 0s;
    }
  }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 767px) {
  .my-navbar .btn-how-it-work {
    padding: 10px 16px !important;
  }
  
  // .navbar-toggler {
    // position: absolute;
    // right: 1em;
  // }
  .desktop {
    display: none !important;
  }

  .modal-content {
    // width: 80% !important;
  }

  .modal-header {
    padding-left: 0;
    padding-right: 0;
    border-top: none;
  }

  .modal-footer {
    padding-left: 0;
    padding-right: 0;
  }
  // nav
  .me.navbar-nav {
    width: 100vw !important;
  }
  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
  .navbar-toggler {
    margin-left: 0;
    margin-right: auto;
  }
  .gift-counter {
    left: 12px !important;
    top: 4px !important;
  }
  .page-getstarted .container > * {
    // display: inline-block;
  }
  .navbar__right {
    display: none !important;
  }
  .mobile-balance {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    width: 65%;
    justify-content: flex-end;
  }
  // end nav
  .front-content {
    margin-left: auto;
    margin-right: auto;
    width: 81% !important;
    top: 25%;
  }
  #envelope-content.front-content.default {
    top: 36%;
    font-size: 14px !important;
  }
  #envelope-recipient.front-recipient.default,
  #envelope-sender.front-sender.default {
    font-size: 14px !important;
  }
  .input-heading.animation-logo {
    top: 1.6em !important;
  }

  .modal {
    &-title {
      font-size: 1.2em;
    }
    &-body {
      .howto {
        font-size: 16px;
      }
    }
    .modal-footer-cancel {
      padding: 10px 3em;
    }

    .modal-footer-submit {
      padding: 10px 3em;
    }
    &-header {
      button.close {
        // margin-top: -66px !important;
        color: transparent;
        right: 10px !important;
      }
    }
    &-header .close > span:first-child {
      font-size: 33px !important;
    }
  }
  .my-navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .my-navbar.navbar img {
    max-height: 27px !important;
    max-width: 80px !important;
    padding-top: 4px;
  }
  // .page-search__utility .row {
  // justify-content: center;
  // }
  .env-outer.drop-env {
    -webkit-animation: drop-env 1.8s ease-in-out 0.8s forwards;
    animation: drop-env 1.8s ease-in-out 0.8s forwards;
    animation-delay: 4.8s + $animation-delay;
    &.skip-animation {
      animation: drop-env 1.8s ease-in-out 0.8s forwards;
      animation-delay: 0s;
      animation-duration: 0s;
    }
  }
  .my-navbar {
    flex-wrap: wrap !important;
    &-bottom {
      .btn {
        &-topup {
          position: absolute;
          right: 1em;
        }
      }
    }
    .navbar-nav {
      width: 100vw;
      justify-content: flex-end;
      flex-direction: column;
      position: absolute;
      left: -16px;
      padding: 20px 40px;
      background: white;
      // color: var(--primary);
      top: 48px;
    }

    .navbar-toggler {
      border-color: transparent !important;
      &-icon {
        color: white;
      }
    }

    .navbar__left {
      flex-direction: column;
      width: 100% !important;
    }

    .navbar__left > a {
      width: 100%;
      display: inline-flex;
      color: var(--primary) !important;
      span {
        color: var(--gf-neutral-500);
      }
    }

    .nav-balance {
      width: 35%;
      text-align: right;
      &-title {
        font-size: 14px;
        justify-content: flex-end;
      }
    }

    .navbar-brand {
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
      // z-index: 10;
    }

    .btn-topup-mobile {
      background-color: #e4fffc;
      border-radius: 30px;
      font-weight: 600;
      min-width: 100px;
      min-height: 35px;
    }
    .navbar-toggler:focus,
    .navbar-toggler:hover {
      outline: none;
    }
  }
  .page-getstarted {
    .membership-envelope {
      text-align: center !important;
      .btn-envelope {
        width: 45%;
        font-size: 0.8em;
      }
    }
    .membership-card {
      margin-top: 1em !important;
      margin-bottom: 1em;
    }
    .content {
      padding-left: 15px;
      padding-right: 15px;
      // padding-top: 0 !important;
    }
    .btn-voucher {
      margin-top: 0.5rem !important;
    }
  }

  .top-up-modal {
    .modal-title {
      font-size: 1.2em;
    }
    // .modal-header {
    //   button.close {
    //     margin-top: -40px !important;
    //   }
    // }
  }
  .btn {
    &-back {
      padding-left: 0 !important;
    }
  }
  .modal-confirm {
    .text-qty {
      text-align: right;
    }
    .modal-title {
      font-size: 1rem !important;
      line-height: 22px;
      margin-top: 0.5rem;
    }
    .modal-body {
      padding: 0;
      .row {
        padding-left: 1em;
        padding-right: 1em;
      }
      .attention {
        font-size: 18px;
      }
      .merchant {
        &__total-order {
          border-top: 1px solid #d5d5d5;
          padding-top: 0.5em;
        }
      }
    }
  }
  .modal-terms {
    // .modal-header {
    //   .close {
    //     margin-top: -40px !important;
    //   }
    // }
    .modal-body {
      .row {
        padding-left: 0;
        padding-right: 0;
      }
      img {
        width: 100%;
      }
    }
  }
  .page-mygifts {
    .navbar-back {
      left: 1em !important;
    }
    .voucher-list {
      position: relative;

      &:after {
        display: block;
        content: '';
        z-index: -1;
        background: white;
        width: 100vw;
        height: 100%;
        position: absolute;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
      }
      &.disabled:after {
        z-index: 1;
        height: 100% !important;
        background: #d5d5d5 !important;
        position: absolute !important;
        opacity: 0.5;
        top: 0;
      }
    }
  }
  .btn-envelope {
    font-size: 0.8rem;
  }
}
/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 767px) and (orientation: landscape) {
  .page-getstarted .membership-greet + .membership-envelope {
    display: none !important;
  }
  .merchant-image-wrapper {
    width: 100%;
    .merchant-images {
      &.top-rad {
        height: auto !important;
      }
    }
  }
  .page-search .dropdown .dropdown-toggle[aria-expanded='true'] {
    transform: translateX(-50vh) !important;
  }
  .page-search .page-search__utility .show + .dropdown--filter {
    left: 26vh !important;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-getstarted .hero .gift-card {
    left: 320px !important;
  }

  .page-getstarted .hero .plane {
    left: 400px !important;
  }
  .page-search__utility .btn-link.btn {
    min-width: 10em !important;
  }

  .page-mygifts .min-height-alter {
    min-height: calc(100vh - 610px);
  }

  // .page-search .redemption-navbar {
  // margin-bottom: 23px !important;
  // }

  .btn-topup {
    padding: 10px 20px !important;
  }

  .page-search .btn-back svg {
    display: none;
  }

  .page-search__bar:after {
    right: -7px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .page-mygifts .min-height-alter {
    min-height: calc(100vh - 610px);
  }
  .btn-how-it-work {
    padding: 10px 16px !important;
  }
  .navbar-toggler {
    // position: absolute;
    // right: 1em;
  }
  .modal-dialog {
    max-width: 80% !important;
  }
  .modal-confirm {
    .text-qty {
      text-align: right;
    }
    .modal-body .attention {
      font-size: 18px;
    }
  }
  .btn-back {
    font-size: 14px !important;
  }
  .page-search .dropdown .dropdown-toggle[aria-expanded='true'] {
    top: 0 !important;
    right: auto !important;
  }
  .page-search .dropdown-menu.show {
    transform: translate3d(-200px, 60px, 0) !important;
  }
  .page-search__utility .btn-link.btn {
    min-width: 5em !important;
    padding: 8px 1.8em !important;
  }
  .page-search__bar {
    overflow: hidden;
    &:after {
      right: -4px !important;
    }
  }

  // nav
  .my-navbar {
    flex-wrap: wrap !important;
    &-bottom {
      .btn {
        &-topup {
          position: absolute;
          right: 1em;
        }
      }
    }
    .navbar-nav {
      width: 100vw;
      justify-content: flex-end;
      flex-direction: column;
      position: absolute;
      left: -32px;
      padding: 20px 40px;
      background: white;
      // color: var(--primary);
      top: 58px;
      /* &:after {
        content: '';
        width: 0;
        height: 0;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ffffff transparent;
        border-style: solid;
        position: absolute;
        bottom: 100%;
        left: 129px;
      } */
    }

    .navbar-toggler {
      border-color: transparent !important;
      top: 5px;
      &-icon {
        color: white;
      }
    }

    .navbar__left {
      flex-direction: column;
      width: 100% !important;
    }

    .navbar__left > a {
      width: 100%;
      display: inline-flex;
      color: var(--primary) !important;
      span {
        color: var(--gf-neutral-500);
      }
    }

    .nav-balance {
      width: 35%;
      text-align: right;
      &-title {
        font-size: 14px;
        justify-content: flex-end;
      }
    }

    .navbar-brand {
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
      // z-index: 10;
    }

    .btn-topup-mobile {
      background-color: #e4fffc;
      border-radius: 30px;
      font-weight: 600;
      min-width: 100px;
      min-height: 35px;
    }
    .navbar-toggler:focus,
    .navbar-toggler:hover {
      outline: none;
    }
  }
  .me.navbar-nav {
    width: 100vw !important;
  }
  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
  .navbar-toggler {
    margin-left: 0;
    margin-right: auto;
  }
  .gift-counter {
    left: 12px !important;
    top: 4px !important;
  }
  .page-getstarted .container > * {
    // display: inline-block;
  }
  .navbar__right {
    display: none !important;
  }
  .mobile-balance {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    width: 65%;
    justify-content: flex-end;
  }
  // end nav
  .page-search .footer {
    bottom: auto !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .section--cards {
    padding-top: 10%;
    max-width: 70%;
  }

  .front-sender {
    font-size: 25px;
    top: 75%;
    right: 20%;
  }

  .front-recipient {
    font-size: 25px;
    top: 33%;
    left: 20%;
  }

  .front-content {
    font-size: 16px;
    line-height: 1;
    top: 30%;
    left: 20%;
    &.default {
      top: 35%;
    }
  }

  .env-outer.rotate-env {
    -webkit-animation: rotate-env 0.6s ease-in-out 1.8s forwards;
    animation: rotate-env 0.6s ease-in-out 1.8s forwards;
    animation-delay: 4.8s + $animation-delay;
    &.skip-animation {
      animation: rotate-env 0.6s ease-in-out 1.8s forwards;
      animation-delay: 0s;
      animation-duration: 0s;
    }
  }
  .page-mygifts {
    .navbar-back {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .btn-envelope {
    font-size: 0.9rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mobile {
    display: none !important;
  }
  .navbar-expand-lg .navbar-nav {
    align-items: center;
  }
  .section--cards {
    padding-top: 10%;
    display: none;
    max-width: 60%;
  }

  .front-sender {
    // font-size: 30px;
    top: 75%;
    right: 20%;
  }

  .front-recipient {
    // font-size: 30px;
    top: 33%;
    left: 20%;
  }

  .text-qty {
    text-align: right;
  }

  .front-content {
    font-size: 19px;
    line-height: 1.1;
    word-spacing: 1px;
    top: 40% + $position-fixer;
    left: 20%;
  }
  .navbar-nav {
    .gift-counter {
      left: 26px !important;
    }
    .nav-balance-title {
      font-size: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .nav-balance-count {
      font-size: 16px !important;
      margin-left: 20px;
      letter-spacing: 0;
    }

    .nav-link {
      svg {
        margin-right: 0 !important;
        // color: var(--primary);
        color: var(--giftano-card-redemption-nav-font-color);
        margin-bottom: 8px !important;
      }
      .navbar-btn__text {
        // color: var(--primary);
        color: var(--giftano-card-redemption-nav-font-color);
        font-weight: normal;
        font-size: 14px;
      }
      &.active {
        position: relative;
      }
      &.active:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: var(--primary);
        bottom: -8px;
        left: 0;
        right: 0;
        opacity: 0.4;
      }
      &.active .navbar-btn__text {
        // color: var(--primary-darker);
        color: var(--giftano-card-redemption-nav-font-color);
      }
      &.active svg {
        // color: var(--primary-darker);
        color: var(--giftano-card-redemption-nav-font-color);
      }
    }
    .navbar__right {
      .nav-link {
        svg {
          margin-bottom: 0 !important;
        }
      }
    }
    .navbar__left {
      justify-content: space-evenly;
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .page-getstarted {
    .content .membership-card {
      background-size: contain !important;
      height: 196px !important;
    }
  }
  .page-mygifts {
    .navbar-back {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .section--cards {
    padding-top: 10%;
    display: none;
    width: 40%;
  }

  .front-sender {
    // font-size: 25px;
    top: 75%;
    right: 20%;
  }

  .front-recipient {
    // font-size: 25px;
    top: 35%;
    left: 20%;
  }

  .front-content {
    // font-size: 19px;
    line-height: 1;
    word-spacing: 1px;
    top: 34%;
    left: 20%;
  }
}
