.page-mygifts {
  @include MyNavbar(128px);
  // .container {
  // padding-bottom: 3rem;
  // }

  .redemption-navbar {
    height: 30px;
  }

  .nav-input {
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid var(--gf-neutral-300);
    .form-control {
      border: none;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .btn {
      background-color: white;
      border-color: transparent;
    }
  }

  .mygift__banner {
    @include media-breakpoint-down(sm) {
      padding: 0;
      top: -8px;
    }
  }

  .empty-gift {
    text-align: center;
    .empty-image {
      width: 230px;
    }
  }

  .voucher {
    &-list {
      @include card-shading;
      .voucher-price {
        @include font-16-bold;
      }
      .voucher-merchant-name {
        @include font-14-light;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .btn-link {
        @include font-14-bold;
      }

      &.voucher-list-disabled-container {
        background-color: #eeeeee !important;
      }
      .voucher-list-disabled-color {
        background-color: #eeeeee !important;
      }
      .list-group-item {
        padding: 10px;
      }
    }
    &-status {
      position: absolute;
      top: 20%;
      z-index: 10;
      transform: rotate(-10deg);
      color: #ae0d01;
      font-size: 21px;
      font-weight: 700;
      border: 4px solid #ae0d01;
      display: inline-block;
      padding: 0.25rem 1rem;
      text-transform: uppercase;
      border-radius: 0;
      font-family: 'Courier';
      text-align: center;
      /* -webkit-mask-image: url(https://giftano.com/img/grunge.png);
      -o-mask-image: url(https://giftano.com/img/grunge.png);
      -moz-mask-image: url(https://giftano.com/img/grunge.png);
      mask-image: url(https://giftano.com/img/grunge.png);
      mask: url(https://giftano.com/img/grunge.png); */
      -webkit-mask-size: 400px 200px;
      -o-mask-size: 400px 200px;
      -moz-mask-size: 400px 200px;
      mask-size: 400px 200px;
      mix-blend-mode: multiply;
      left: 0;
      right: 0;
      height: 45px;
      width: 220px;
      margin: auto;
    }
    &-image {
      @media only screen and (max-width: 767px) {
        text-align: center;
        margin-bottom: 1rem;
      }
    }
    &-tag {
      background-color: $luxi-gold;
      color: white;
      border-radius: 4px;
      padding: 2px 8px;
      font-size: 12px;
    }
    &-merchant-name {
      font-size: 14px;
      line-height: 22px;
      color: rgba(51, 51, 51, 0.45);
      margin-bottom: 4px;
    }
    &-description {
      font-size: 14px;
      line-height: 22px;
      color: rgba(51, 51, 51, 0.45);
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-validity {
      background-color: rgba(2, 175, 160, 0.08);
      border-radius: 8px;
      padding: 8px 24px;
      color: rgba(51, 51, 51, 0.65);
      font-size: 14px;
      line-height: 22px;
    }
    &-barcode {
      border-top: 1px dashed #bdbdbd;
      border-width: 2px;
      margin-top: 1rem;
      padding-top: 10px;
      & svg {
        border: 1px solid #e0e0e0;
      }
    }
    &-expiry {
      margin-top: 1em;
      margin-bottom: 1em;
      padding: 0.7em;
      border-radius: 8px;
      background-color: #daf3f1;
      color: var(--gf-neutral-500);
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }

  //   * plugin
  .clamp-lines__button {
    @include clamp-line;
  }

  .swipezor-but {
    @include swipezor;
  }

  @media only screen and (max-width: 767px) {
    .rectangle-header {
      height: 154px;
    }
  }

  // animated toggler
  /* .button-cover {
    height: 100px;
    margin: 20px;
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
  }

  .button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
  } */
  .button,
  .knobs,
  .layer {
    position: absolute;
    top: -6px;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }

  .button {
    position: relative;
    // top: 50%;
    width: 104px;
    height: 20px;
    // margin: -20px auto 0 auto;
    overflow: hidden;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .button.b2 {
    border-radius: 2px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 20px;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 30;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: $giftano-orange;
    transition: 0.3s ease all;
    z-index: 1;
  }

  /* Toggle Expired */
  #toggle-expired {
    // display: inline-block;
    // float: left;
  }
  .toggle-label {
    vertical-align: top;
    // margin-left: 1em;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1;
  }
  #toggle-expired .knobs:before {
    content: 'YES';
    position: absolute;
    top: 8px;
    left: 4px;
    width: 45px;
    height: 15px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 2px;
    background-color: var(--primary);
    border-radius: 12px;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  }

  #toggle-expired .checkbox:active + .knobs:before {
    width: 46px;
    border-radius: 100px;
  }

  #toggle-expired .checkbox:checked:active + .knobs:before {
    margin-left: -26px;
  }

  #toggle-expired .checkbox:checked + .knobs:before {
    content: 'NO';
    left: 54px;
    background-color: $giftano-red;
  }

  #toggle-expired .checkbox:checked ~ .layer {
    background-color: #fcebeb;
  }
  // end animated toggler

  .container > .mb-2.row {
    position: relative;
    // min-height: calc(100vh - 330px);
  }

  .footer {
    margin-top: 40px;
  }
}

.mygift-modal {
  // .close {
  // margin-top: -3rem !important;
  // }
  .modal {
    &-content {
      border: none;
    }
    &-header {
      border-top: 1em solid var(--primary);
      border-bottom: 0;
    }
    &-title {
      font-size: 21px;
      // text-align: center;
      text-transform: uppercase;
      // color: var(--primary);
      font-weight: 600;
      width: 100%;
      svg {
        height: 1.5em;
        width: 1.5em;
        margin-top: -3px;
      }
    }
    &-body {
      text-align: center;
      font-size: 18px;
    }
    &-footer {
      // background-color: #daf3f1;
      // border-top: 0;
      justify-content: center;
    }
  }
}

.disabled {
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #d5d5d5;
    position: absolute;
    opacity: 0.5;
    top: 0;
  }
  .voucher-status {
    display: none;
  }
  .voucher-info {
    opacity: 0;
  }
  .redeem-status {
    position: absolute;

    z-index: 10;
    transform: rotate(-9deg);
    color: #cc3125;
    font-weight: 700;
    border: 4px solid #cc3125;
    display: inline-block;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    border-radius: 0;
    -webkit-mask-size: 400px 200px;
    -o-mask-size: 400px 200px;
    -moz-mask-size: 400px 200px;
    mask-size: 400px 200px;
    mix-blend-mode: multiply;
    top: 0;
    bottom: 0;
    right: 10%;
    font-size: 34px;
    height: 84px;
    line-height: 66px;
    width: 219px;
    margin: auto;
  }
}

.modal {
  .redeem-status {
    position: absolute;
    top: 30px;
    z-index: 10;
    transform: rotate(-9deg);
    color: #ae0d01;
    font-size: 21px;
    font-weight: 700;
    border: 4px solid #ae0d01;
    display: inline-block;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    border-radius: 0;
    -webkit-mask-size: 400px 200px;
    -o-mask-size: 400px 200px;
    -moz-mask-size: 400px 200px;
    mask-size: 400px 200px;
    mix-blend-mode: multiply;
    left: 0;
    right: 0;
    height: 45px;
    width: 219px;
    margin: auto;
  }
  &-redemption {
    // .close {
    //   margin-top: -40px;
    // }
    .modal {
      &-content {
        border: none;
      }
      &-header {
        // border-top: 1em solid var(--primary);
        border-bottom: 0;
        padding: 1.5rem;
      }
      &-title {
        font-size: 21px;
        // text-align: center;
        text-transform: uppercase;
        color: var(--primary);
        font-weight: 600;
        width: 100%;
        svg {
          height: 1.5em;
          width: 1.5em;
          margin-top: -3px;
        }
      }
      &-body {
        img {
          border-radius: 8px;
        }
        text-align: center;
        font-size: 18px;
        padding: 1.5rem;
        .redemption-info {
          display: flex;
          margin-bottom: 1em;
          img {
            margin-right: 1.5em;
          }
          h4 {
            align-self: center;
          }
        }
        .form-label {
          text-align: left;
        }
        .showcode {
          &-title {
            h4 {
              font-weight: bold;
              margin-top: 1em;
            }
          }
          &-detail {
            display: block;
            width: 100%;
            position: relative;
            margin-bottom: 3em;
            span {
              font-size: 16px;
              padding: 1.2em;
              text-align: center;
              display: block;
              background-color: #f1f1f1;
              overflow-wrap: break-word;
              font-weight: bold;
              width: 50%;
              margin-left: auto;
              margin-right: auto;
            }
          }
          &-barcode {
            margin-top: 1em;
            margin-bottom: 10px;
            padding: 12px;
            border: 1px solid #e0e0e0;
            width: 50%;
            max-height: 90px;
            img {
              margin-bottom: 0.6em;
              width: 100%;
            }
          }
        }
      }
      &-footer {
        // background-color: #daf3f1;
        border-top: 0;
        justify-content: center;
        button.btn {
          border-radius: 24px;
          font-size: 14px;
          padding: 0.5rem 4.2rem;
          color: white;
        }
        button.btn-primary {
          background-color: var(--primary);
          color: var(--primary-contrast);
          border-color: transparent;
          width: 70%;
          margin-bottom: 2em;
          transition: 0.3s ease-in background-color;
          &:hover {
            background-color: var(--primary-darker);
          }
        }
      }
    }
    .form-control {
      border-radius: 0;
      background-color: #f1f1f1;
      font-size: 16px;
      padding: 1.2em;
      border-color: transparent;
    }
  }
  &-redemptionsuccess {
    // .close {
    // margin-top: 0 !important;
    // }
    .modal {
      &-content {
        border: none;
      }
      &-header {
        border-top: 1em solid var(--primary);
        border-bottom: 0;
        padding: 1.5rem;
      }
      &-title {
        font-size: 21px;
        // text-align: center;
        text-transform: uppercase;
        color: var(--primary);
        font-weight: 600;
        width: 100%;
        svg {
          height: 1.5em;
          width: 1.5em;
          margin-top: -3px;
        }
      }
      &-body {
        text-align: center;
        font-size: 18px;
        padding: 1.5rem;
        .redemption-success {
          display: flex;
          margin-bottom: 1em;
          img {
            margin-right: 1.5em;
            height: intrinsic;
          }
          h4 {
            align-self: center;
          }
          &-detail {
            margin-top: 1em;
            margin-bottom: 10px;
            padding: 12px;
            border: 1px solid #e0e0e0;
            h4 {
              // color: var(--primary);
              margin-bottom: 1em;
            }
            h5 {
              text-align: left;
            }
            .form-control {
              border-radius: 0;
              background-color: #f1f1f1;
              font-size: 16px;
              padding: 1.2em;
              border-color: transparent;
            }
          }
        }
        .form-label {
          text-align: left;
        }
      }
      &-footer {
        background-color: #daf3f1;
        border-top: 0;
        justify-content: center;
        button.btn {
          border-radius: 24px;
          font-size: 14px;
          padding: 0.5rem 4.2rem;
          color: white;
        }
        button.btn-primary {
          background-color: var(--primary);
          color: var(--primary-contrast);
          border-color: transparent;
        }
      }
    }
  }
  &-detailcode {
    .redeem-status {
      top: 2em;
    }
    .modal {
      &-content {
        border: none;
      }
      &-header {
        border-top: 1em solid var(--primary);
        border-bottom: 0;
        padding: 1.5rem;
      }
      &-title {
        font-size: 21px;
        text-transform: uppercase;
        color: var(--primary);
        font-weight: 600;
        width: 100%;
        svg {
          height: 1.5em;
          width: 1.5em;
          margin-top: -3px;
        }
      }
      &-body {
        text-align: center;
        font-size: 18px;
        padding: 1.5rem;
        .voucher-details-info {
          img {
            border-radius: 8px;
            margin-right: 1em;
            height: 100px;
            object-fit: cover;
          }
          h4 {
            margin-top: 1em;
          }
          display: flex;
        }
        .voucher-detail-status {
          position: absolute;
          right: 1.5em;
          top: 2em;
          background-color: #c1a302;
          color: white;
          border-radius: 4px;
          padding: 2px 8px;
          font-size: 12px;
        }
        .voucher-details-barcode {
          margin-top: 1em;
          margin-bottom: 1em;
          padding: 1em;
          border-radius: 8px;
          background-color: #daf3f1;
          img {
            margin-bottom: 0.6em;
          }
          h4 {
            margin-bottom: 0;
          }
        }
      }
      &-footer {
        background-color: #daf3f1;
        border-top: 0;
        justify-content: center;
        button.btn {
          border-radius: 24px;
          font-size: 14px;
          padding: 0.5rem 4.2rem;
          color: white;
        }
        button.btn-primary {
          background-color: var(--primary);
          color: var(--primary-contrast);
          border-color: transparent;
        }
      }
    }
  }
  &-showcode {
    .modal {
      &-content {
        border: none;
      }
      &-header {
        border-top: 1em solid var(--primary);
        border-bottom: 0;
        padding: 1.5rem;
      }
      &-title {
        font-size: 21px;
        // text-align: center;
        text-transform: uppercase;
        color: var(--primary);
        font-weight: 600;
        width: 100%;
        svg {
          height: 1.5em;
          width: 1.5em;
          margin-top: -3px;
        }
      }
      &-body {
        text-align: center;
        font-size: 18px;
        padding: 1.5rem;
        margin-top: -1.5em;
        .showcode-info {
          display: flex;
          margin-bottom: 1em;
          img {
            margin-right: 1.5em;
          }
          h4 {
            align-self: center;
          }
        }
        .showcode {
          &-detail {
            display: block;
            width: 100%;
            position: relative;
            span {
              font-size: 16px;
              padding: 1.2em;
              text-align: center;
              display: block;
              background-color: #f1f1f1;
              overflow-wrap: break-word;
              font-weight: bold;
            }
          }
          &-barcode {
            margin-top: 1em;
            margin-bottom: 10px;
            padding: 12px;
            border: 1px solid #e0e0e0;
            width: 100%;
            max-height: 90px;
            img {
              margin-bottom: 0.6em;
              width: 100%;
            }
          }
        }

        .showcode-barcode {
          img {
            margin-bottom: 0.6em;
            width: 100%;
          }
          h4 {
            margin-bottom: 0;
          }
        }
        .showcode-print {
          position: relative;
          .btn {
            // position: absolute;
            // right: 15px;
            // bottom: -12px;
            // border-radius: 8px;
            background-color: white;
            color: var(--primary);
            font-weight: bold;
            width: 100%;
            height: 60px;
            border-color: transparent;
            // box-shadow: 0 10px 9px -4px rgba(3, 175, 160, 0.6);
          }
        }
        .showcode-additional {
          margin-top: 1em;
          text-align: left;
          h4 {
            margin-bottom: 0.6em;
            font-weight: 600;
          }
          p {
            white-space: pre-wrap;
          }
        }
      }
      &-footer {
        background-color: #daf3f1;
        border-top: 0;
        justify-content: center;
        button.btn {
          border-radius: 24px;
          font-size: 14px;
          padding: 0.5rem 4.2rem;
          color: white;
        }
        button.btn-primary {
          background-color: var(--primary);
          color: var(--primary-contrast);
          border-color: transparent;
        }
      }
    }
  }
  &-terms {
    .modal-body {
      padding-top: 0;
    }
    .terms-merchant {
      color: var(--primary-darker);
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid var(--gf-neutral-200);
      padding-bottom: 15px;
    }
  }
}

@media screen and (max-width: 767px) {
  // .mygift__banner {
  //     margin-top: 28px;
  // }
  .page-mygifts .title {
    margin-top: 2em !important;
    h3 {
      margin: 0 0 25px;
      font-size: 20px;
    }
  }
  .voucher-list-disabled-container:after {
    background: #eeeeee !important;
  }
  .voucher-list-disabled-color {
    .voucher-info {
      position: absolute;
      .voucher-status {
        top: 0;
        left: auto;
        right: 1em;
        height: 45px;
        width: 192px;
        font-size: 18px;
      }
    }
  }
  .voucher-list {
    position: relative;
    box-shadow: none !important;
    border-bottom: 1px solid var(--gf-neutral-200);
    border-radius: 0 !important;
    .list-group-item {
      padding: 5px 0;
    }
  }
  .modal-redemption {
    .modal-body > .row {
      padding-left: 0;
      padding-right: 0;
    }

    .modal-body img {
      width: 100%;
      // max-width: 200px;
    }
    .showcode-title {
      // text-align: left;
      text-align: center;
    }
    .modal-body .showcode-barcode {
      width: 100%;
    }
    .modal-body .showcode-detail span {
      width: 100%;
    }
  }
  .modal-showcode {
    .redeem-status {
      left: auto;
      top: -3.4em;
      font-size: 14px;
      height: 35px;
      width: 170px;
      transform: rotate(-14deg);
      right: 1.3em;
    }
    .modal-body > .row {
      padding-left: 0;
      padding-right: 0;
      &.showcode-text {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .modal-body .showcode-additional {
      padding-bottom: 3em;
      h4 {
        font-size: 1.2em;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .voucher-list-disabled-color {
    .voucher-info {
      .voucher-status {
        width: 12em;
        height: 3em;
        font-size: 0.8em;
      }
    }
  }
}

.btn-back {
  color: #000;
}

.show-code-url {
  padding-top: 30px;
  padding-bottom: 50px;
}
