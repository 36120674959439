.greeting-card{
    position: relative;
    opacity: 1;
    transform: none;
    width: 100%;
    &__sections{
        width: 100%;
        max-width: 100%;
        padding: 0;
    }
    &__modal{
        padding-bottom: 2em;
        padding-left: 0;
        padding-right: 0;
        padding-top: 1em;
    }
    picture{
        box-shadow: none;
    }
}