/* custom specific style for page */
.passcode-page {
  @media only screen and (max-width: 767px) {
    .logo {
      /* height: 10px; */
      margin-bottom: 100px;
    }
    .card-redeem {
      max-width: none !important;
      padding: 15px !important;
      h3 {
        font-size: 24px !important;
      }
      .form-group .form-control {
        font-size: 24px !important;
      }
    }
  }
  /*
  @media only screen and (max-width: 374px) {
    .card-redeem {
      .form-group .form-control {
      }
    }
  }
 */
  .shape-header {
    height: 100px;
    background-color: var(--primary);
  }
  .logo {
    margin-top: 15px;
    margin-bottom: 150px;
    height: 60px;
  }
  .card-redeem {
    max-width: 580px;
    margin: auto;
    padding: 16px 2.4em;
    box-shadow: 0px 8px 16px 2px rgba($color: #333333, $alpha: 0.16);
    h3 {
      color: var(--primary);
      font-size: 24px;
      line-height: 54px;
      text-align: center;
      font-family: 'lato';
      font-weight: 600;
    }
    .form-group {
      background-color: #f1f1f1;
      padding: 16px 24px;
      border-radius: 8px;

      .form-label {
        text-align: center;
        color: #828282;
        margin-bottom: 16px;
      }
      .form-control {
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #c4c4c4;
        border-radius: 0;
        margin-bottom: 16px;
        color: black;
        padding-left: 0;
        padding-right: 0;
      }
      /* remove input type number spin button */
      input[type='number'] {
        -moz-appearance: textfield;
      }
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* end remove spin button */
      .form-control:focus {
        border-bottom-color: var(--primary);
        outline: 0;
        box-shadow: none;
      }
      .giftano-card-number {
        font-size: 14px !important;
        padding: 0px;
      }
      .col-giftano-card-number {
        width: 5%;
      }
    }
    button {
      border-radius: 24px;
      background-color: var(--primary);
      border-color: var(--primary);
      width: 75%;
      margin: 0 auto;
    }
    .recaptcha-text {
      text-align: left;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 16px;
      margin-bottom: 8px;
      a {
        color: var(--primary);
      }
    }
  }
  .shape-bottom {
    background: url('../assets/images/redemption-bg.png');
    position: fixed;
    width: 100%;
    height: 100vh;
    background-size: 100%;
    background-position: center bottom;
    z-index: -100;
    // opacity: 0.25;
    .rectangle-18 {
      position: absolute;
      width: 535.95px;
      height: 535.95px;
      background: #f2994a;
      transform: rotate(-45deg);
      top: -268px;
      left: 108px;
    }
    .ellipse-3 {
      position: absolute;
      width: 380px;
      height: 380px;
      left: 457px;
      top: 19px;
      border-radius: 100%;
      background: #f2c94c;
    }
    .rectangle-17 {
      position: absolute;
      width: 386px;
      height: 386px;
      left: -5px;
      top: 270.94px;
      background: #eb5757;
      transform: rotate(-45deg);
    }
    .ellipse-2 {
      position: absolute;
      width: 661px;
      height: 661px;
      left: 908px;
      top: -227px;
      background: #f2c94c;
      border-radius: 100%;
    }
    .ellipse-1 {
      position: absolute;
      width: 358px;
      height: 358px;
      left: 766px;
      top: 398px;
      background: #9b51e0;
      border-radius: 100%;
    }
    .ellipse-4 {
      position: absolute;
      width: 358px;
      height: 358px;
      left: 472px;
      top: 422px;
      background: #eb5757;
      border-radius: 100%;
    }
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
