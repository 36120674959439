.page-merchant {
  @include MyNavbar(128px);

  .card-skeleton {
    display: block;
  }
  .card-skeleton-mobile {
    display: none;
  }
  .container {
    // max-width: 580px;
  }
  .subtitle {
    color: #000 !important;
    font-weight: 700 !important;
  }
  .rectangle-header {
    background-color: var(--primary);
    height: 128px;
  }
  .merchant-images {
    border-radius: 8px !important;
  }

  .logo {
    margin-top: 32px;
    margin-bottom: 8px;
    height: 135px;
    width: auto;
    max-height: none;
    font-size: 18px;
    line-height: 28px;
  }
  .carousel-inner {
    border-radius: 8px;
  }
  .merchant-product__list {
    margin-top: 30px;
    margin-bottom: 50px;
    .card-total {
      &:nth-child(n + 2) {
        border-bottom: 1px solid var(--gf-neutral-200) !important;
        border-radius: 0 !important;
      }
      &:last-child {
        border-bottom: none !important;
      }
    }
    .card-title {
      background-color: var(--gf-light-gray-300);
      padding: 20px 15px;
      border-radius: var(--gf-border-radius-m);
      font-weight: 700;
    }
    .cart {
      @include card-shading;
      margin-top: 28px;
      &__key {
        @include font-16-light;
        color: var(--gf-neutral-400);
        line-height: 48px;
      }
      &__action {
        margin: 20px auto;
        padding: 1rem 3rem;
        display: block;
        border-radius: var(--gf-border-radius-l);
        min-width: 50%;
      }
    }
  }
  .merchant-name {
    @include font-41-bold;
  }
  .merchant-name-long {
    font-size: 1.5em !important;
  }
  .merchant-desc {
    @include font-16-light;
    color: var(--gf-neutral-400);
    & + .btn-link {
      padding: 0;
      margin-bottom: 1em;
      color: var(--primary);
      font-weight: 500;
    }
  }
  .voucher-card {
    border-radius: 8px !important;
    background-color: transparent;
    border: none;
    .card-title {
      background-color: var(--gf-light-gray-300);
      padding: 20px 15px;
      border-radius: var(--gf-border-radius-m);
    }
  }

  .voucher-list {
    border-radius: 8px !important;
    img {
      height: 80px;
      object-fit: contain;
      padding-right: 5px;
      border-radius: var(--gf-border-radius-m);
    }
  }
  .voucher-name {
    @include font-16-bold;
    margin-bottom: 4px;
  }
  .voucher-desc {
    color: var(--gf-neutral-400);
    margin-top: 0px;
    @include font-14-light;
    position: relative;
    margin-bottom: 0px;
  }
  .voucher-sum {
    color: $giftano-orange;
    font-weight: bold;
    font-size: 18px;
    line-height: 48px;
  }

  .list-group {
    &-item {
      padding: 15px;
      box-shadow: var(--gf-shadow-thin);
      border-radius: var(--gf-border-radius-m);
    }
  }

  .clamped {
    --max-lines: 3;
    position: relative;
    // max-height: $line-height;
    height: 67px;
    overflow: hidden;
    padding-right: 1rem; /* space for ellipsis */
    &:before {
      position: absolute;
      content: '';
      inset-block-end: 0; /* "bottom" */
      inset-inline-end: 0; /* "right" */
    }
    &:after {
      content: '...';
      position: absolute;
      inset-inline-end: 0;
      width: 7.1rem;
      height: 24px;
      padding-bottom: 0;
      background: white;
      bottom: 0;
      right: 0;
    }
  }
  .clamp-lines__button {
    @include clamp-line;
  }
  .voucher-toggle {
    position: absolute;
    right: 20px;
    bottom: 19px;
    cursor: pointer;
    color: $primary-color;
  }
  .voucher-info {
    padding-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .voucher-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .voucher-price {
    font-weight: bold;
    @include font-16-bold;
    color: var(--gf-primary-300);
  }
  .voucher-qty {
    border: 1px solid var(--gf-neutral-200);
    border-radius: 30px;
    background-color: var(--gf-neutral-100);
    // margin-top: 2em;
    width: 100%;
    justify-content: center;
    .btn {
      background-color: transparent;
      border-color: var(--gf-neutral-200);
      padding: 0.375rem 1rem;
    }
    .form-control {
      border: none;
      background-color: var(--gf-neutral-100);
    }
    .form-control:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  .card-total {
    .card-body {
      padding: 10px 15px;
    }
    // background-color: #e0e0e0;
    &.card-has-cart {
      .card-body {
        padding-top: 0;
        padding-bottom: 0;
        p {
          color: #6e6e6e;
        }
      }
    }

    &.merchant__buy {
      padding-bottom: 0;
    }

    .merchant__amount-topup {
      position: relative;
      &:before {
        // content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: #f3f3f3;
      }
    }
    border: none;
    border-radius: 8px;
    // padding-top: 10px;
    padding-bottom: 10px;
    // margin-top: 20px;

    h4 {
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
      font-size: 18px;
      line-height: 48px;
      margin: 0;
    }
    .btn {
      background-color: var(--primary);
      color: var(--primary-contrast);
      border: none;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.2;
      transition: 0.3s ease-in background-color;
      &:hover {
        background-color: $primary-dark;
      }
    }
    .btn-alt {
      background-color: #fc9e4f;
      @media screen and (max-width: 1023px) {
        margin-top: 2em;
        font-size: 14px;
      }
    }
  }
  .footnote {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 16px;
    margin-bottom: 75px;
  }
  .merchant-container {
    min-height: calc(100vh - 195px);
    p {
      @include font-16-light;
      color: var(--gf-neutral-400);
    }
  }

  .gift-box {
    // navigation carousel next and prev gift
    .btn-gift-control {
      margin-top: 30px;
      color: var(--primary);
    }
    // inside carousel item
    .carousel-inner {
      border-radius: 0;
    }
    .gift-carousel-item {
      .gift-image {
        border-radius: 4px;
        margin-bottom: 10px;
      }
      .gift-merchant-name {
        color: var(--gf-soft-orange-400, '#CA7E3F');
        padding: 0;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
      }
      .gift-name {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 0;
      }
      .hr {
        margin-top: 0;
        margin-bottom: 25px;
      }
      p {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        margin-bottom: 0;
      }
      .merchant-desc-clamp-btn {
        color: var(--gf-primary-400);
        padding: 0;
      }
      .btn-select-gift {
        margin-top: 25px;
        margin-bottom: 0;
        height: 50px;
        font-size: 18px;
      }
    }

    .other-gifts {
      @include full-width;
      padding-bottom: 30px;
      background: var(--gf-dark-violet-200, '#443A4E');
      padding-top: 25px;
      .title {
        margin-top: 25px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .gift-container {
        overflow-x: scroll;
        margin-top: 15px;
        padding-left: 15px;
        padding-bottom: 10px;
        scroll-behavior: smooth;
        position: relative;
        .gift-scroller {
          width: max-content;
          .gift-box {
            width: 260px;
            display: inline-block;
            margin-right: 20px;
            padding: 0;
            border: 0;
            border-radius: var(--gf-border-radius-l);
            .card {
              border: 0;
              .card-body {
                padding: 10px 15px;
              }
            }
            .gift-image {
              height: 130px;
            }
            .merchant-name {
              color: var(--gf-soft-orange-400, '#CA7E3F');
              margin-top: 0;
              margin-bottom: 5px;
              font-size: 16px;
            }
            .gift-name {
              font-size: 16px;
              font-weight: 700;
              min-height: 42px;
            }
            .gift-desc {
              font-size: 14px;
              font-weight: 500;
            }
            .btn-view {
              padding: 0px;
              font-size: 14px;
              line-height: 20px;
              height: 37px;
              width: 120px;
            }
          }
        }
      }
    }
  }
  .carousel-root {
    margin-bottom: 1em;
  }
}

.modal-merchant-info {
  .contacts {
    margin-bottom: 30px;
    p {
      margin-bottom: 0px;
    }
  }
}

.modal-confirm .modal-title p {
  font-size: 16px;
  margin-top: 3px;
}

.modal-shipment {
  .input-group-text,
  .form-control {
    background-color: #f5f5f5;
    border: none;
  }
  .btn-send-gift {
    background-color: var(--primary);
    border-radius: 24px;
    color: #f1f1f1;
    border: none;
    font-size: 14px;
    line-height: 22px;
    padding-left: 24px;
    padding-right: 24px;
    height: 48px;
    margin-top: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .page-merchant {
    .carousel-root {
      margin-bottom: 0;
    }
    .card-skeleton {
      display: none;
    }
    .card-skeleton-mobile {
      display: block;
    }
    .breadcrumb .breadcrumb-item.active {
      max-width: 200px;
      height: 20px;
      -webkit-line-clamp: 1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .rectangle-header {
      height: 0 !important;
    }
    // .redemption-navbar {
    //   margin-top: 60px;
    // }
    .logo {
      height: 50px;
    }
    .merchant-name {
      font-size: 1.2em;
      margin: 15px 0;
    }
    .voucher-price {
      width: 60%;
      margin-bottom: 0;
    }
    .voucher-actions {
      flex-direction: initial;
      align-items: center;
    }
    .voucher {
      &-info {
        padding-left: 0;
      }
      &-qty {
        margin-top: 0;
      }
    }
    .merchant-name {
      font-size: 1.2em;
    }
    .merchant__amount-topup {
      &:before {
        width: 100vw;
        left: -35px;
      }
    }
    .card-total {
      &.merchant__buy {
        position: fixed;
        bottom: 0;
        z-index: 30;
        width: 100%;
        background-color: white;
        box-shadow: var(--gf-shadow-thin) !important;
        left: 0;
        padding-left: 1em;
        padding-right: 1em;
      }

      &.card {
        &-has-balance,
        &-has-order,
        &-has-cart {
          &:before {
            display: block;
            content: '';
            z-index: -1;
            background: white;
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
          }
        }
        &-has-cart {
          .card-title {
            position: relative;
            &:before {
              display: block;
              content: '';
              z-index: -1;
              background: $dark;
              width: 100vw;
              height: 100%;
              position: absolute;
              top: 0;
              left: 50%;
              right: 50%;
              margin-left: -50vw;
              margin-right: -50vw;
            }
          }
        }
      }
    }
    .list-group {
      &-item {
        position: relative;
        box-shadow: none;
        margin-bottom: 5px !important;
        margin-top: 5px !important;
        &:before {
          background: white;
          @include full-width-container;
          box-shadow: var(--gf-shadow-thin);
        }
      }
    }
    .merchant-product__list {
      .card-title {
        position: relative;
        z-index: 0;
        &:before {
          @include full-width-container;
          background-color: var(--gf-light-gray-300);
          z-index: -1 !important;
        }
      }
    }
    .cart {
      position: relative;
      box-shadow: none;
      // margin-bottom: 5px !important;
      margin-top: 5px !important;
      &:before {
        background: white;
        @include full-width-container;
        box-shadow: var(--gf-shadow-thin);
      }
      &__container {
        align-items: center;
      }
      &__key {
        font-weight: bold !important;
        color: #000 !important;
      }
      &__action {
        margin-right: 0 !important;
      }
    }
    .footer {
      display: none;
    }
    .merchant-product__list .cart {
      margin-bottom: 100px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .rectangle-header {
    height: 0 !important;
  }
  // .redemption-navbar {
  //   padding-top: 65px;
  //   height: 60px;
  // }
  .merchant-name {
    margin: 20px 0;
  }
}
