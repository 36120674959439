.my-navbar {
  .nav {
    &-link {
      text-align: center;
    }
  }
  .navbar-nav > div {
    width: 50%;
    display: flex;
  }
  .navbar__right {
    justify-content: flex-end;
    align-items: center;
  }
  .nav-balance-title {
    display: flex;
    align-items: center;
  }

  .navbar-nav {
    width: 100%;
  }

  .collapsing {
    transition: height none !important;
  }
}
