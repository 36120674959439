@import '../theme/utility';

.modal-terms {
  .modal-body {
    .voucher-tnc--html {
      @include font-14-light;
      color: var(--gf-neutral-400);
    }
  }
}
